@import 'styles/includes';

.InfoText {
    $root: &;

    padding: 4rem 0;
    background-color: $black;

    @include media(l) {
        padding: 8rem 0;
    }

    &__Container {
        @extend %container;
    }

    &__Inner {
        padding: 1rem 2rem;
        border: 0.1rem solid $white;

        @include media(l) {
            padding: 4rem 3rem;
        }
    }

    &__RichText {
        @extend %richtext;
        margin: 0 auto;
        width: 100%;
        color: $white;

        p {
            font-size: 1rem;
            line-height: 130%;

            @include media(l) {
                font-size: 1.6rem;
            }
        }
    }
}
